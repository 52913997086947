import * as React from 'react';
import { Colour } from '@Models';

interface IProps {
}

export class ArrowSvg extends React.Component<IProps, {}> {

    public render() {
        return <svg className="arrow-svg" viewBox="0 0 31.5 31.5">
            <path d="M21.2 5c-0.4-0.4-1.1-0.4-1.6 0 -0.4 0.4-0.4 1.1 0 1.6l8 8H1.1C0.5 14.6 0 15.1 0 15.7c0 0.6 0.5 1.1 1.1 1.1h26.6l-8 8c-0.4 0.4-0.4 1.2 0 1.6 0.4 0.4 1.2 0.4 1.6 0l10-10c0.4-0.4 0.4-1.1 0-1.6L21.2 5z" fill="#1E201D"/>
        </svg>
    }
}
