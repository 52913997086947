import { ClassableProps } from '@jcharlesworthuk/your-mum-core/dist/functions';
import * as React from 'react';

type Props = ClassableProps & {
}

const PlusIconSvg: React.FunctionComponent<Props> = (props: Props) => {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.449 13.449" className={props.className}>
            <g transform="translate(-48.27 -60.088)" stroke="#494949">
                <circle cx="54.996" cy="66.813" r="5.485" fill="#f4f5f7" strokeWidth=".48" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M55.04 63.775v6.21M58.146 66.903h-6.21" fill="none"/>
            </g>
        </svg>
    
}

export { PlusIconSvg };
