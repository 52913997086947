import * as React from 'react';

interface IProps {
    className?: string;
}

export class WavyLineSvg extends React.Component<IProps, {}> {

    public render() {
        return <svg className={this.props.className} viewBox="-0.7 0 169 5.6" width="169" height="6">
            <path d="M-0.7 5.7V2c0.3 1 2.8 1.9 3.8 1.9 2.4-0.1 4.9-1.1 6.3-2.1C12.3-0.5 17.8-1.1 23.6 2c5.8 3.1 8.5-3 16.6-0.3 8.1 2.7 9.2 1.8 11.9 0.1 2.9-2.3 8.4-2.9 14.2 0.2 5.8 3.1 11.5-3.7 16.6-0.3 5.1 3.4 9.2 1.8 11.9 0.1 2.9-2.3 8.5-2.9 14.2 0.2 5.8 3.1 8.5-3 16.6-0.3 8.1 2.7 9.2 1.8 11.9 0.1 2.9-2.3 8.4-2.9 14.2 0.2 5.8 3.1 10.1-3.2 16.6-0.3v3.9z" />
        </svg>
    }
}

