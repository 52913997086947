import * as React from 'react';
import { Link, GatsbyLinkProps } from "gatsby";
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { ensureTrailingSlash } from '@jcharlesworthuk/your-mum-core/dist/functions';

type Props = GatsbyLinkProps<{}> & { href?: string };

const LinkWrapper : React.FunctionComponent<Props> = props => {
    const url = props.to || props.href;
    if (url.startsWith('https://'))
      return <OutboundLink href={url} target="_blank" className={props.className} onClick={props.onClick}>
        {props.children}
      </OutboundLink>

    return <Link
      className={props.className}
      activeClassName={props.activeClassName}
      activeStyle={props.activeStyle}
      innerRef={props.innerRef}
      onClick={props.onClick}
      to={ensureTrailingSlash(url)}>
      {props.children}
    </Link>
  }


  export default LinkWrapper;
