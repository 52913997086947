import * as React from 'react';

export class ListIconSvg extends React.Component<{}, {}> {

    public render() {
        return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.449 13.449">
        <path d="M10.29 12.247l-3.601-1.984-3.676 1.843.774-4.038L.899 5.142l4.079-.512L6.868.979 8.616 4.7l4.056.67-2.999 2.812z" fill="#494949" stroke="#494949" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    }
}
