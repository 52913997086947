import * as React from 'react';

interface Props {
    fillStep: number;
    filledColour?: string;
}

const StarSvg: React.FunctionComponent<Props> = (props: Props) => {

        const grey = '#d1d4d6';
        const filled = props.filledColour || '#88cd88';
        const fillLeft = props.fillStep > 0 ? filled : grey;
        const fillRight = props.fillStep > 0.5 ? filled : grey;
            return <svg enableBackground="new 0 0 501.28 501.28" version="1.1" viewBox="0 0 501.28 501.28">
        <polygon points="501.28 194.37 335.26 159.33 250.64 12.27 250.64 419.77 405.54 489.01 387.56 320.29" fill={fillRight} />
        <polygon points="166.02 159.33 0 194.37 113.72 320.29 95.74 489.01 250.64 419.77 250.64 12.27" fill={fillLeft} />
    </svg>
    
}

export { StarSvg };
