import * as React from 'react';
import { StaticQuery } from 'gatsby';
import { Site } from '@GraphQLModels';
import { graphql } from "gatsby"
import { Helmet } from "react-helmet";
import { ensureTrailingSlash } from '@jcharlesworthuk/your-mum-core/dist/functions';

interface PageMetaProps {
  title?: string;
  description?: string;
  imageUrl?: string;
  route: string;
  children: JSX.Element[] | JSX.Element;
}


interface StaticQueryData {
  site: Site
}


const PageMeta: React.FunctionComponent<PageMetaProps> = (props: PageMetaProps) => {

  return <StaticQuery
    query={pageMetaQuery}
    render={(data: StaticQueryData) => {
      const seo = {
        title: props.title || data.site.siteMetadata.title,
        description: props.description || data.site.siteMetadata.description,
        image: `${data.site.siteMetadata.siteUrl}${(props.imageUrl || data.site.siteMetadata.siteLogoUrl)}`,
        url: ensureTrailingSlash(`${data.site.siteMetadata.siteUrl}${props.route}`),
      }
      return (
        <>
          <Helmet title={seo.title} titleTemplate={data.site.siteMetadata.titleTemplate}>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            <meta name="theme-color" content="#053075" />
            <meta name="mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta property="og:url" content={seo.url} />
            <meta property="og:title" content={seo.title} />
            <meta property="og:description" content={seo.description} />
            <meta property="og:image" content={seo.image} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content={`@${data.site.siteMetadata.social.twitter.handle}`} />
            <meta name="twitter:creator" content={`@${data.site.siteMetadata.social.twitter.handle}`} />
            <meta name="twitter:title" content={seo.title} />
            <meta name="twitter:description" content={seo.description} />
            <meta name="twitter:image" content={seo.image} />
            {props.children}
          </Helmet>
        </>
      );
    }} />
}

export default PageMeta;


const pageMetaQuery = graphql`
      query PageMetaQuery {
        site {
      siteMetadata {
        title
        titleTemplate
        themeColour
        longDescription
        siteUrl
        social {
          twitter {
            handle
          }
        }
      }
    }
    }
  `
