import * as React from 'react';
import MediaQuery from 'react-responsive';
import sassVariables from 'sass-old/variables';

interface IProps {
    className?: string;
}

export class ArticleWaveSvg extends React.Component<IProps, {}> {

    public render() {
        return <div className={this.props.className}>
            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="-0.7 0 66.8 26"><path d="M-0.7-0.9V21c25.9-2.6 38.9 1 53.1 0.6 9.9-0.3 13.6-1.8 13.6-1.8 0.2-17 0-20.7 0-20.7z"/></svg>
        </div>
    }
}





