import { ClassableProps } from '@jcharlesworthuk/your-mum-core/dist/functions';
import * as React from 'react';

type Props = ClassableProps & {
    hex: string
    onClick?: () => void;
 }

const CircleGlossySvg: React.FunctionComponent<Props> = (props: Props) => {

return <svg viewBox="0 0 150.655 150.655" className={props.className} onClick={props.onClick} >
    <path d="M215.431 506.092c0-116.98 94.83-211.812 211.811-211.812 116.981 0 211.812 94.832 211.812 211.812 0 116.98-94.83 211.812-211.812 211.812-116.98 0-211.81-94.831-211.81-211.812" fill={`${props.hex}`} transform="matrix(.3462 0 0 -.3462 -72.58 250.532)"/>
    <g transform="matrix(.34118 0 0 -.34118 -56.381 264.176)">
        <path d="M368.572 724.167c-19.544-34.627.494-82.948 44.755-107.932 44.262-24.981 95.986-17.164 115.53 17.464 19.544 34.627-.494 82.948-44.755 107.932-17.487 9.869-36.137 14.619-53.509 14.619-26.599 0-50.198-11.137-62.021-32.083" fill="url(#circleGlossyLight)"/>
</g>

</svg>
}

const CircleGlossySvgDefs: React.FunctionComponent<{}> = () => {

    return <svg width="0" height="0" viewBox="0 0 150.655 150.655" style={{position: 'absolute'}}>
    <defs>
        <radialGradient id="circleGlossyLight" spreadMethod="pad" gradientTransform="matrix(96.58373 -54.51348 -38.81506 -68.77019 448.716 678.933)" gradientUnits="userSpaceOnUse" r="1" cy="0" cx="0" fy="0" fx="0">
            <stop offset="0" stopOpacity="0.6" stopColor="#fff"/>
            <stop offset="1" stopOpacity="0" stopColor="#fff"/>
        </radialGradient>
    </defs>
    </svg>
    }
    
export { CircleGlossySvg, CircleGlossySvgDefs };