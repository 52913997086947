import { ClassableProps } from '@jcharlesworthuk/your-mum-core/dist/functions';
import * as React from 'react';

type Props = ClassableProps & {
    hex: string
    onClick?: () => void;
 }

const CircleMattSvg: React.FunctionComponent<Props> = (props: Props) => {

return <svg viewBox="0 0 150.655 150.655" className={props.className} onClick={props.onClick}>
    <path d="M215.431 506.092c0-116.98 94.83-211.812 211.811-211.812 116.981 0 211.812 94.832 211.812 211.812 0 116.98-94.83 211.812-211.812 211.812-116.98 0-211.81-94.831-211.81-211.812" fill={`${props.hex}`} transform="matrix(.3462 0 0 -.3462 -72.58 250.532)"/>
</svg>
}

export { CircleMattSvg };