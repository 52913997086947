import * as React from 'react';
import { Colour } from '@Models';

interface IProps {
    colour: string;
}

export class PaintDropSvg extends React.Component<IProps, {}> {

    public render() {
        const rgbColour = Colour.hexToRgb(this.props.colour);
        rgbColour[0] -= 20;
        rgbColour[1] -= 20;
        rgbColour[2] -= 20;
        const darkerColour = Colour.rgbToHex(rgbColour[0], rgbColour[1], rgbColour[2]);
        return <svg className="paint-drop-svg" width="706.01" height="652.14" version="1.1" viewBox="0 0 706.01047 652.13669" >
            <g transform="translate(9.9375)">
                <path d="m225.41 20.393c-6.525 0-12.653 3.1294-16.486 8.4004-8.127 11.174-198.86 274.99-198.86 388 0 118.74 96.605 215.34 215.34 215.34 118.75 0 215.37-96.603 215.37-215.34 0-113.01-190.74-376.83-198.86-388-3.844-5.272-9.9709-8.4004-16.506-8.4004z" fill={this.props.colour} stroke={darkerColour} strokeWidth="40" />
                <path d="m123.45 414.75c0-11.267-9.125-20.392-20.391-20.392-11.256 0-20.392 9.125-20.392 20.392 0 78.018 63.489 141.5 141.51 141.5 11.256 0 20.392-9.115 20.392-20.392 0-11.257-9.136-20.392-20.392-20.392-55.536 1e-3 -100.72-45.187-100.72-100.71z" fill={darkerColour} />
                <path d="m580.28 28.401c-3.844-5.271-9.981-8.401-16.507-8.401s-12.652 3.13-16.486 8.401c-15.997 22.013-95.789 134.58-95.789 189.2 0 61.909 50.356 112.28 112.28 112.28 61.909 0 112.3-50.367 112.3-112.28-0.01-54.619-79.802-167.19-95.789-189.2z" fill={this.props.colour} stroke={darkerColour} strokeWidth="40" />
            </g>
        </svg>
    }
}
