import { ClassableProps } from '@jcharlesworthuk/your-mum-core/dist/functions';
import * as React from 'react';

type Props = ClassableProps & { 
    hex: string
    onClick?: () => void;
}

const CircleMetallicSvg: React.FunctionComponent<Props> = (props: Props) => {

return <svg viewBox="0 0 150.655 150.655" className={props.className} onClick={props.onClick}>
    <path d="M215.431 506.092c0-116.98 94.83-211.812 211.811-211.812 116.981 0 211.812 94.832 211.812 211.812 0 116.98-94.83 211.812-211.812 211.812-116.98 0-211.81-94.831-211.81-211.812" fill={`${props.hex}`} transform="matrix(.3462 0 0 -.3462 -72.58 250.532)"/>
<path d="M215.431 506.092c0-116.98 94.83-211.812 211.811-211.812 116.981 0 211.812 94.832 211.812 211.812 0 116.98-94.83 211.812-211.812 211.812-116.98 0-211.81-94.831-211.81-211.812" fill="url(#circleMetalBody)" transform="matrix(.3462 0 0 -.3462 -72.58 250.532)"/>
<g transform="matrix(.34118 0 0 -.34118 -56.381 264.176)">
        <path d="M368.572 724.167c-19.544-34.627.494-82.948 44.755-107.932 44.262-24.981 95.986-17.164 115.53 17.464 19.544 34.627-.494 82.948-44.755 107.932-17.487 9.869-36.137 14.619-53.509 14.619-26.599 0-50.198-11.137-62.021-32.083" fill="url(#circleMetalLight)"/>
</g>
</svg>
}

const CircleMetallicSvgDefs: React.FunctionComponent<{}> = () => {

    return <svg width="0" height="0" viewBox="0 0 150.655 150.655" style={{position: 'absolute'}}>
    <defs>
        <radialGradient id="circleMetalLight" spreadMethod="pad" gradientTransform="matrix(96.58373 -54.51348 -38.81506 -68.77019 448.716 678.933)" gradientUnits="userSpaceOnUse" r="1" cy="0" cx="0" fy="0" fx="0">
            <stop offset="0" stopOpacity="0.6" stopColor="#fff"/>
            <stop offset=".429" stopOpacity="0" stopColor="#fff"/>
        </radialGradient>
        <radialGradient id="circleMetalBody" spreadMethod="pad" gradientTransform="matrix(343.25677 0 0 -343.25677 483.242 635.092)" gradientUnits="userSpaceOnUse" r="1" cy="0" cx="0" fy="0" fx="0">
            <stop offset="0" stopColor="#fff" stopOpacity="0.01" />
            <stop offset=".177" stopColor="#fff" stopOpacity="0.02" />
            <stop offset=".306" stopColor="#fff" stopOpacity="0.01" />
            <stop offset=".43" stopColor="#fff" stopOpacity="0.02" />
            <stop offset=".476" stopColor="#fff" stopOpacity="0.05" />
            <stop offset=".581" stopColor="#fff" stopOpacity="0.2" />
            <stop offset=".587" stopColor="#fff" stopOpacity="0.2" />
            <stop offset=".656" stopColor="#fff" stopOpacity="0.15" />
            <stop offset=".806" stopColor="#fff" stopOpacity="0.05" />
            <stop offset="1" stopColor="#fff" stopOpacity="0.02" />
        </radialGradient>
    </defs>
    </svg>
    }
    
export { CircleMetallicSvg, CircleMetallicSvgDefs };