import * as React from 'react';
import './BigWavyCurveSvg.scss';
import { baseClassAnd, childClassClosure, ClassableProps } from '@jcharlesworthuk/your-mum-core/dist/functions';

type Props = ClassableProps;

const baseClass = 'big-wavy-curve';
const childClass = childClassClosure(baseClass);

const BigWavyCurveSvg: React.FunctionComponent<Props> = (props: Props) => {

    return <div className={baseClassAnd(baseClass, props)}>
        <svg className={childClass('small')} xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="-0.7 0 66.7 44"><path d="M-0.7-0.9V35c0.1 0.3 3.3 2.5 7.6 0.9 5.1-1.9 12.8 5.7 16.7 0.9 4-4.8 7.1 1.1 12.3 1.2 2 0 5.8-3.4 8.5-1.8 5.9 3.4 9.2-0.1 11.8 2 2.7 2.1 10 5.3 9.6-6.3 0.3-17 0.1-32.8 0.1-32.8z" /></svg>
        <svg className={childClass('medium')} xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="-0.7 0 80.4 64"><path d="M-0.7-1.4V34.6c0.3 1 2.2 2.8 5.8 2.8 3.6 0 6.5-3.3 9.6-5.5 3.2-2.2 6.5 2.8 13 1 6.5-1.8 3.6 8.6 11.5 5.5 14.6-5.9 7.5 23.6 16.2 16.9 8.7-6.7 9.2 6.6 17.9 6.5 4.4 0 5.2-1.6 6.3-4.3 0.3-17 0-58.7 0-58.7z" /></svg>
        <svg className={childClass('large')} xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="-0.7 0 223.4 64"><path d="m-0.7-1.4v35.9c0.1 0.3 4 2.5 9.2 0.9 6.2-1.9 13 2.6 20.2 0.9 8.5-2 12.2 3.6 17.9 1.5 5.6-2.1 13.9-0.5 15.2-0.4 5.3 0 9.4-3.3 14.1-5.5 4.6-2.2 9.5 2.8 19 1 9.5-1.8 5.3 8.6 16.8 5.5 21.3-5.9 10.9 23.6 23.6 16.9 12.7-6.7 13.3 6.6 26.1 6.5 1.6 0 9.9-2.1 15.5-3.8 7.9-2.5 13.4 4.5 20.1 2.2 6.9-2.4 13.9 1.4 19.2 0.9 5.3-0.5 5.9-2.9 6.3-3.6 0.4-17-0.1-58.7-0.1-58.7z" /></svg>
    </div>
}




export { BigWavyCurveSvg };